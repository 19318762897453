import React from "react";
import styled from "@emotion/styled";

import one from "../images/donkeys/1.jpg";
import two from "../images/donkeys/2.jpg";
import three from "../images/donkeys/3.jpg";
import four from "../images/donkeys/4.jpg";
import five from "../images/donkeys/5.jpg";
import six from "../images/donkeys/6.jpg";
import seven from "../images/donkeys/7.jpg";
import eight from "../images/donkeys/8.jpg";
import nine from "../images/donkeys/9.jpg";
import ten from "../images/donkeys/10.jpg";
import eleven from "../images/donkeys/11.jpg";
import twelve from "../images/donkeys/12.jpg";
import thirteen from "../images/donkeys/13.jpg";

const PfpFlipperContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const pfpList = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
];

export default class PfpFlipper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPfpIndex: 0,
    };
  }

  componentDidMount() {
    this._clearInterval = setInterval(this._updatePfp, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._clearInterval);
  }

  _updatePfp = () => {
    this.setState({
      currentPfpIndex: (this.state.currentPfpIndex + 1) % pfpList.length,
    });
  };

  render() {
    const { currentPfpIndex } = this.state;
    return (
      <PfpFlipperContainer>
        <img alt="pfp" src={pfpList[currentPfpIndex]} />
      </PfpFlipperContainer>
    );
  }
}
