import jason from "../../../images/jason.png";
import biggun from "../../../images/biggun.png";
import donkus from "../../../images/donkus.jpg";
import vp from "../../../images/flying-donkey.png";
import donk from "../../../images/Dean.jpg";
import lbusiness from "../../../images/lordbusiness.png";
import cale from "../../../images/cale.png";
import cmon from "../../../images/cmon.png";
const team = [
  {
    twitterName: "@_ChaosTurkey",
    twitterLink: "https://twitter.com/_chaosturkey",
    title: "Donkus Maximus",
    job: "Gen 1&2 Art",
    imageUrl: donkus,
  },
  {
    twitterName: "@Biggunn01",
    twitterLink: "https://twitter.com/biggunn01",
    title: "Biggunn01",
    job: "Gen 2 Art",
    imageUrl: biggun,
  },
  {
    twitterName: "@FlunkDev",
    twitterLink: "https://twitter.com/FlunkDev",
    title: "D̶o̶n̶k̶ Dean",
    job: "Development",
    imageUrl: donk,
  },
  {
    twitterName: "@ClassicsCrypto",
    twitterLink: "https://twitter.com/ClassicsCrypto",
    title: "Jason B",
    job: "Community Advisor",
    imageUrl: jason,
  },
  {
    twitterName: "@L0rd_Bizness",
    twitterLink: "https://twitter.com/L0rd_Bizness",
    title: "Lord Business",
    job: "Marketing",
    imageUrl: lbusiness,
  },
  {
    twitterName: "@FlunkDonkFlunk",
    twitterLink: "https://twitter.com/FlunkDonkFlunk",
    title: "CaleMATH",
    job: "Tokenomics and Development",
    imageUrl: cale,
  },
  {
    twitterName: "@shots_sharp",
    twitterLink: "https://twitter.com/shots_sharp",
    title: "Cmontudah",
    job: "Community Advisor",
    imageUrl: cmon,
  },
];

export default team;
