import styled from "@emotion/styled";
import { boxShadow, COLORS } from "../../Components/Common";

export const Container = styled.div`
  background: url("https://v2glk4ffiyuae4oxfxznj7o4doiziigjxyr7bumcj3a64plczdkq.arweave.net/roy1cKVGKAJx1y3y1P3cG5GUIMm-I_DRgk7B7j1iyNU/?ext=PNG")
    0% 0% / cover no-repeat;
  height: calc(100vh - 80px);
  width: 100%;
  background-position: center;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100%;
  background: ${COLORS.transparentBlack};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: ${boxShadow};
  min-width: 400px;
`;

export const CurrencySuffix = styled.span`
  font-size: 0.65em;
  margin-left: -2px;
  font-weight: 600;
`;

export const InfoContainer = styled.div`
  background: ${COLORS.blue};
  color: #fefefe;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: sans-serif;
  text-align: left;

  table {
    font-size: 0.9em;
    width: 100%;

    th {
      width: 55%;
    }
  }
`;
