import React from "react";
import styled from "@emotion/styled";
import {
  SectionContainer,
  Header,
  COLORS,
  envelopeBackground,
} from "../../../Components/Common";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px;
  background-color: ${COLORS.lightGreen};
  background-image: ${envelopeBackground};
  background-attachment: fixed;
  background-size: cover;
  h2 {
    margin-bottom: 50px;
    color: white;
  }
`;

const Lore = () => {
  return (
    <SectionContainer id="lore">
      <Content style={{ padding: "50px 10%" }}>
        <Header>Lore</Header>
        <p>
          <b>Chapter 1: Genesis</b>
        </p>
        <br />
        <p>
          The Flunk Donkeys Community College is home to the most delinquent
          students on the Solana Blockchain. Attendees are born from a wide
          spectrum of broken homes and crystal chandelier mansions.
        </p>
        <br />
        <p>
          Despite having the lowest academic and athletic performance in the
          metaverse, they are one of the best funded schools. For the longest
          time they have been using the studen's low performance to request
          additional funding for “services” and “programs” curtailed to their
          delinquent student population. The Board, not knowing what else to do
          with these students and, truth be told, not wanting to have to
          relocate them to other schools, always oblige.
        </p>
        <br />
        <p>
          The head of the school, Dean, loves this arrangement and takes full
          advantage of it, spending most of his time entertaining guests at the
          yacht club close by and traveling to his vacation homes throughout the
          school year (paid by school funds obviously). He has carefully put in
          place a team to ensure the students stay rowdy as heck but just get
          by.
        </p>
        <br />
        <p>
          The main Professor at the school specializes in Flunk Science and is
          determined to ensure these students embrace their flunk natures and
          that they make something of themselves. The Coach, a washed up
          long-jump world champion, is responsible for athletics and making
          students some of the best jumpers in the area (has unintended
          consequences, usually criminal). Lastly, the Fraternity President is
          one of the most legendary drop-outs the school has ever seen. Because
          of his family connections he's been able to remain on campus as a
          middle-aged student. He never goes to class, is the head of the Frat
          house, eats, drinks and sleeps for free, while giving members a true
          orientation in all things Flunk.
        </p>
        <br />
        <p>
          <b>Chapter 2: Perfect Students</b>
        </p>
        <br />
        <p>
          The FDCC closed out the school year on a high note, which is very bad
          news for the Dean and his team. The school receives plenty of
          additional funding because they have the most delinquent students in
          the area and need to keep it that way if they want to keep the money
          flowing.
        </p>
        <br />
        <p>
          School is out for the summer and the Dean finds himself in a bit of a
          predicament…If the academic and athletic performance of their students
          is anywhere close to what it was this year, come next year, funding is
          going to be pulled. The Dean will be forced to sell his boat, a few of
          his vacation homes and say goodbye to his membership at the Yacht Club
          close by. What's worse is they might start attracting more competent
          students and teachers to the school, making any possibility for future
          funding slim.
        </p>
        <br />
        <p>
          One evening, the Dean, Coach, Frat President and Professor met in
          private to discuss the situation and figure out a plan. They came up
          with the idea of experimenting on some of their worst students forced
          to take additional classes over the summer creating traits they can
          fuse together producing the most delinquent creatures the metaverse
          has ever seen. This would ensure they can maintain an extremely high
          delinquency rate so they don't run into the same issue ever again.
        </p>
      </Content>
    </SectionContainer>
  );
};

export default Lore;
