import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  getPhantomWallet,
  getSolletWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import RarityPage from "./RarityPage";
import Landing from "./LandingPage";

import "./App.scss";
import { NETWORK } from "./constants";
import { getRpcUrl } from "./utils/solana";
import StakePage from "./StakePage";
import { DonkConnectorProvider } from "donk-connector";

const App = () => {
  const wallets = [
    getPhantomWallet(),
    getSolflareWallet(),
    getSolletWallet({ network: NETWORK }),
  ];

  return (
    <div className="App">
      <ChakraProvider>
        <ConnectionProvider endpoint={getRpcUrl(NETWORK)}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider featuredWallets={3}>
              <DonkConnectorProvider>
                <Router>
                  <Switch>
                    <Route path="/stake">
                      <StakePage />
                    </Route>
                    <Route path="/rarity">
                      <RarityPage />
                    </Route>
                    <Route path="/">
                      <Landing />
                    </Route>
                  </Switch>
                </Router>
              </DonkConnectorProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ChakraProvider>
    </div>
  );
};

export default App;
