import React from "react";
import rarityData from './rarity.json';
import supplyData from './supply.json';
// import statuses from './statuses.json';
import dorms from './dorms.json';
import grades from './grades.json';

import {
    Header,
    Text,
    SectionContainer,
    CopyContainer,
  } from "../Components/Common";
  import styled from "@emotion/styled";

import './Rarity.scss';

const supply = {}
const percent = {}
supplyData.forEach((layer) => {
    layer.children.forEach((asset) => {
        supply[asset.name + layer.name] = asset.supply 
        percent[asset.name + layer.name] = (asset.supply/100)+ '%';
    })
})

const formmatedRarityData = rarityData.map((layer) => {
    layer.children.sort((a, b) => {
        const aSupply = supply[a.name + layer.name]
        const bSupply = supply[b.name + layer.name]

        return bSupply - aSupply
    })

    return layer
})

const formattedGrades = Object.keys(grades).map((grade) => {
    const amount = grades[grade]

    return {
        name: grade,
        supply: amount,
        percent: (amount/100)+ '%'
    }
})
formattedGrades.sort((a, b) => {
    return b.supply - a.supply
})

const formattedDorms = Object.keys(dorms).map((grade) => {
    const amount = dorms[grade]

    return {
        name: grade,
        supply: amount,
        percent: (amount/100)+ '%'
    }
})

formattedDorms.sort((a, b) => {
    return b.supply - a.supply
})



// .map((layer) => {
//     layer.children = layer.children.map((asset) => {
//         const length = asset.name.length + asset.rarity.length 
//         const dotsLength = 30 - length;
//         let dots = ""
//         for(let i = 0; i < dotsLength; i++) {
//             dots += '.'
//         }
//         return {...asset, dots}
//     })
//     return layer
// })

export const Text2 = styled(Text)`
  margin: 0 auto;
  text-align: left;
  position: relative;
  left: 5px;
  width: 400px;
`;

// const dorm = [
//     "Jumento Commons",
//     "Catalan Hall",
//     "Andulusian Tower",
//     "Asinara Houses"
// ]

// const statuses = [
//     // extra rare
//     'Campus Hobo',
//     "Dean's List",

//     //rare
//     'Banned from Campus',
//     'Class President',

//     //uncommon
//     'Expelled for Behavior',
//     'Suspended',
//     'Dunce',
//     "Teacher's Pet",

//     //common
//     'Detention', 
// ]

// const grades = [
//     //common
//     { name: 'D', rarity: 'common'}, { name: 'D+', rarity: 'common'}, { name: 'C-', rarity: 'common'}, { name: 'C', rarity: 'common'}, 

//     //uncommon
//    { name: 'F', rarity: 'uncommon' } ,{ name: 'F+', rarity: 'uncommon' } ,{ name: 'D-', rarity: 'uncommon' } , 
//    { name: 'C+', rarity: 'uncommon' } ,{ name: 'B-', rarity: 'uncommon' } ,{ name: 'B', rarity: 'uncommon' } ,

//     //rare
//     { name: 'B+', rarity: 'rare' }, { name: 'A-', rarity: 'rare' }, 
//     { name: 'F--', rarity: 'rare' }, { name: 'F-', rarity: 'rare' }, 
    
//     //extra rare
//     { name: 'F----', rarity: 'extra rare' },  { name: 'F---', rarity: 'extra rare' }, 
//     { name: 'A', rarity: 'extra rare' },  { name: 'A+', rarity: 'extra rare' }
// ]

export default class Rarity extends React.Component {
  render() {
    return <SectionContainer>
            <CopyContainer slim>
            <dl>
                <Header>Report Card</Header>
                {formattedGrades.map((dorm) => {
                    return <Text2>
                        <dt>{dorm.name}</dt>
                        <dl>{dorm.percent}</dl>
                    </Text2>
                })}
                {formmatedRarityData.map((layer) => {
                    return <>
                        <Header>{layer.name}</Header>
                        {layer.children.map((asset) => {
                            console.log(asset)
                            return <Text2>
                                <dt>{asset.name}</dt>
                                <dl>{percent[asset.name + layer.name]}</dl>
                            </Text2>
                        })}
                    </>
                })}
                <Header>Dorm</Header>
                {formattedDorms.map((dorm) => {
                    return <Text2>
                        <dt>{dorm.name}</dt>
                        <dl>{dorm.percent}</dl>
                    </Text2>
                })}

            </dl>
            </CopyContainer>
    </SectionContainer>
  }
}