import styled from "@emotion/styled";
import { COLORS, BREAKPOINTS } from "../Common";
import { Link as ScrollLink } from "react-scroll";

export const NavLink = styled(ScrollLink)`
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
`;

export const HeaderLink = styled.a`
  margin-right: 20px;
`;

export const Navbar = styled.div<{ white?: boolean; fantasyLeague?: boolean }>`
  color: white;
  background: linear-gradient(
    ${COLORS.navGradientStart},
    ${COLORS.navGradientEnd}
  );

  height: 80px;
  font-family: SchoolBell;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .NavBar__brand {
    margin-left: 20px;
    color: white;
    font-size: 30px;
    font-family: Schoolbell;
    font-weight: 500;
    text-decoration: none;
  }

  .NavBar__links {
    display: flex;
    align-items: center;
  }

  .mint-link {
    text-decoration: none;
    margin-right: 20px;
    cursor: pointer;
    color: black;
    background: white;
    padding: 5px 15px;
    border: 1px solid black;

    &:hover {
      border: 1px solid ${COLORS.blue};
    }
  }

  .rarity-link {
    text-decoration: none;
    margin-right: 20px;
    cursor: pointer;
    color: white;
  }

  .stake-link {
    margin-right: 20px;
  }

  .twitter-logo {
    padding: 3px 3px;
  }
  .discord-logo {
    padding: 1px 1px;
    position: relative;
    top: 1.5px;
  }

  a {
    display: flex;
    align-items: center;
  }

  img {
    height: 25px;
    width: 25px;
    margin-right: 20px;
  }

  .hamburger {
    display: none;
    cursor: pointer;
  }

  ${(p) =>
    p.white &&
    `
    color: black;
    background: white;

    .NavBar__brand {
      color: black
    }
  `}

  @media ${BREAKPOINTS.smallScreenDown} {
    .NavBar__brand {
      font-size: 20px;
    }

    .NavBar__links {
      display: none;
    }

    .hamburger {
      display: flex;
      margin-right: 20px;
    }
  }

  ${(p) =>
    p.fantasyLeague &&
    `
    background: ${COLORS.fantasyBlack};
    color: white;
    .fantasy-league {
      font-family: Roadrage;
      color: ${COLORS.fantasyPink};
      margin-left: 13px;
    }
  `}
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: ${COLORS.transparentWhite};
  color: black;
  z-index: 1000;
  top: 100%;
  right: 0;

  a {
    color: black;
    text-decoration: none;
    padding: 10px 15px;
  }

  span {
    border-bottom: 1px solid black;
  }
`;

export const ConnectButton = styled.div`
  border: 1px solid black;
  padding: 5px 15px;
  margin-right: 20px;
`;
