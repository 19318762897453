import React from "react";
import styled from "@emotion/styled";
import NavBar from "../../Components/NavBar";
import {
  Header,
  Text,
  boxShadow,
  COLORS,
  BREAKPOINTS,
} from "../../Components/Common";

import "./Hero.scss";

import communityCollege from "../../images/communitycollege.jpeg";

const TitleCardContainer = styled.div`
  h2 {
    margin: 0;
  }

  background: white;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;

  a {
    font-family: Schoolbell;
    font-size: 1.8em;
    // font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: ${COLORS.darkGreen};
    color: white;
    padding: 14px;

    &:hover {
      background: ${COLORS.hoverGreen};
    }
  }

  // Mobile Card appears below hero, desktop on top of the hero.
  ${(props) => {
    if (props.mobile) {
      return `
      display: none;
      @media ${BREAKPOINTS.tabletDown} {
          display: flex;
          height: 290px;
      } 
      
      `;
    } else {
      return `
      display: flex;
      max-width: 475px;
      max-height: 330px;
      box-shadow: ${boxShadow};
      @media ${BREAKPOINTS.laptopScreenDown} {
        max-width: 456px;
        max-height: 300px;
        h2 {
          font-size: 1.7em;
        }
      }
      @media ${BREAKPOINTS.tabletDown} {
        display: none;
      }
      `;
    }
  }}
`;
// const SubHeader = styled.h2`
//   font-size: 1.5em;
//   font-family: Sora;
//   font-weight: bold;
// `;

// const Centered = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 12px;
// `;

const TitleCard = ({ mobile }) => (
  <TitleCardContainer mobile={mobile}>
    <Header>Meet the Flunk Donkeys 👋 </Header>
    <Text>
      The rowdiest, rudest collection of delinquent NFTs to ever drop on the
      Solana blockchain.
    </Text>
    {/* <Centered>
      <SubHeader>SOLD OUT</SubHeader>
    </Centered> */}
    <a href="https://canvass.flunkdonkeys.com/">Mint a CanvASS</a>
  </TitleCardContainer>
);

class Hero extends React.Component {
  render() {
    return (
      <div>
        <div className="Hero">
          <NavBar homepage />
          <div
            className="Hero__background"
            style={{
              background:
                "url(" + communityCollege + ") 0% 0% / cover no-repeat",
            }}
          >
            <TitleCard />
            <div className="arrows"></div>
          </div>
        </div>
        <TitleCard mobile />
      </div>
    );
  }
}

export default Hero;
