import React, { useState } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";

import { MobileWalletConnector, WalletConnector } from "../Common";

import { NavLink, Navbar, MenuContainer, HeaderLink } from "./styles";
import { DISCORD_LINK, TWITTER_LINK } from "../../constants";

import twitter from "../../images/twitter.svg";
import discord from "../../images/discord.svg";
import twitterBlack from "../../images/twitter-black.png";
import discordBlack from "../../images/discord-black.png";

const MobileMenu = ({ mintpage }: { mintpage?: boolean }) => {
  const links = [
    <a href={DISCORD_LINK} key="discord">
      <span>Discord</span>
    </a>,
    <a href={TWITTER_LINK} key="twitter">
      <span>Twitter</span>
    </a>,
    <a href="https://howrare.is/flunkdonkeys" key="rarity">
      Rarity
    </a>,
    <Link to="/stake" key="stake">
      Stake
    </Link>,
  ];

  if (mintpage) links.push(<MobileWalletConnector key="wallet-connector" />);
  return <MenuContainer>{links}</MenuContainer>;
};

interface NavBarProps {
  white?: boolean;
  homepage?: boolean;
  mintpage?: boolean;
  fantasyLeague?: boolean;
}

const NavBar = ({ white, homepage, mintpage, fantasyLeague }: NavBarProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const twitterLogo = white ? twitterBlack : twitter;
  const discordLogo = white ? discordBlack : discord;
  const title = fantasyLeague ? (
    <>
      Flunk Donkey <span className="fantasy-league">Fantasy League</span>
    </>
  ) : (
    "Flunk Donkey Community College"
  );

  return (
    <Navbar white={white} fantasyLeague={fantasyLeague}>
      <Link to="/" className="NavBar__brand">
        {title}
      </Link>
      <div className="NavBar__links">
        {homepage && (
          <>
            <NavLink smooth duration={700} to="roadmap">
              Roadmap
            </NavLink>
            <NavLink smooth duration={700} to="team">
              Team
            </NavLink>
            <NavLink smooth duration={700} to="lore">
              Lore
            </NavLink>
            <NavLink smooth duration={700} to="faq">
              FAQ
            </NavLink>
            <HeaderLink href="https://howrare.is/flunkdonkeys" key="rarity">
              Rarity
            </HeaderLink>
            <Link to="/stake" className="stake-link">
              Stake
            </Link>
          </>
        )}
        {mintpage && <WalletConnector />}
        <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
          <img className="twitter-logo" src={twitterLogo} alt="twitter" />
        </a>
        <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
          <img className="discord-logo" src={discordLogo} alt="discord" />
        </a>
      </div>
      <div className="hamburger">
        <HamburgerMenu
          height={15}
          width={18}
          color={white ? "black" : "white"}
          isOpen={menuOpen}
          menuClicked={() => setMenuOpen(!menuOpen)}
          rotate={0}
          animationDuration={0.5}
        />
        {menuOpen && <MobileMenu mintpage={mintpage} />}
      </div>
    </Navbar>
  );
};

export default NavBar;
