import React from "react";

import NavBar from "../Components/NavBar";
import "./Hero.scss";
import dormroom from "../images/dormroom.jpeg";

// Stories of campus legends fill the chatter in the halls of Flunk Donkey Community College. There are rumours of Donkeys who have traveled through time, evidence of the local Frat House's ancient rituals, and even whispers that some students have discovered an entirely new form of explosive consciousness. 
// <br/><br/>As you find your way around the Flunk Donkey campus you are in for a lot of surprises. You’ll find some donkeys partaking in behavior that would surely get them expelled. If you are lucky, you may even catch the Dean on his midnight rounds snooping through the halls trying to hand out as many detentions as possible.
// <br/><br/>

class Hero extends React.Component {
  render() {
    return (
      <div>
        <div className="Hero">
          <NavBar white/>
          <div
            className="Hero__background"
            style={{
              background:
                "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,0.6), rgba(0,0,0,0.7)), url(" +
                dormroom +
                ") 0% 0% / cover no-repeat",
            }}
          >
            <div className="Hero__content">
              If you're looking for a Masters degree in Rarity - you’ll be happy about enrolling here. There are over 100 different traits to collect. 4 Extra Rare, 30 Rare, 42 Uncommon, 67 Common, and more. We can't tell you everything but weve made a guide for you below. Good luck discovering the secrets of these halls.
            </div>
            <div className="arrows"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
