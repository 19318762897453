import React from "react";
import Hero from "./Hero.js";
import Rarity from './Rarity.js';

export default class RaritySection extends React.Component {
  render() {
    return (
      <div className="Rarity">
        <Hero />
        <Rarity/>
      </div>
    );
  }
}
