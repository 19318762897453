import React from "react";
import { RoadmapContent, List } from "../styles";
import { MAX_SUPPLY } from "../../../constants";
import {
  SectionContainer,
  CopyContainer,
  Header,
  Text,
} from "../../../Components/Common";

const RoadmapData = [
  {
    title: "✅ Launch Day - September 10th, 8pm EST",
    description: `All ${MAX_SUPPLY} Flunk Donkeys will be minted and released to our vibrant campus community.`,
  },
  {
    title: "✅ NFT Marketplace Listings",
    description: "Donkeys are available on ALL Solana NFT Marketplaces!",
  },
  {
    title: "✅ The Campus Commons",
    description:
      "A private Discord community for Flunk Donkey holders to congregate, hangout, and chill.",
  },
  {
    title: "✅ up a Decentralized Autonomous Organization (DAO)",
    description:
      "Set up one of the first NFT DAOs on the Solana blockchain using on-chain governance (proposals and voting). ",
  },
  {
    title: "👉 Exclusive mints and $BRAP utility token",
    description:
      "Donkey holders will receive airdrops as well as exclusive access to future mints and the ability to generate $BRAP utility tokens to use within our ecosystem.",
  },
];

const ListItem = ({ title, description }) => {
  return (
    <li>
      <h3>{title}</h3>
      <Text>{description}</Text>
    </li>
  );
};

const Roadmap = () => (
  <SectionContainer id="roadmap">
    <RoadmapContent>
      <CopyContainer>
        <Header>Roadmap</Header>
        <List>
          {RoadmapData.map((data) => (
            <ListItem key={data.title} {...data} />
          ))}
        </List>
      </CopyContainer>
    </RoadmapContent>
  </SectionContainer>
);

export default Roadmap;
