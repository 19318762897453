import { web3 } from "@project-serum/anchor";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

// Flunk Donkey Specific Config
export const FD_CANDY_MACHINE_CONFIG =
  process.env.REACT_APP_FD_CANDY_MACHINE_CONFIG;
export const FD_CANDY_MACHINE_UUID =
  process.env.REACT_APP_FD_CANDY_MACHINE_UUID;
export const FD_START_DATE = process.env.REACT_APP_FD_START_DATE;
export const NETWORK = WalletAdapterNetwork.Mainnet;

// Candy Machine Specific Config
export const CANDY_MACHINE = "candy_machine";
export const PROGRAM_ID = new web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);
export const TOKEN_METADATA_PROGRAM_ID = new web3.PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);
export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new web3.PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);
export const TOKEN_PROGRAM_ID = new web3.PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

export const dummy_start_date = 1631318400 * 1000;

export const MAX_SUPPLY = 5678;

export const DISCORD_LINK = "https://discord.gg/PcGyhGxguZ";
export const TWITTER_LINK = "https://twitter.com/FlunkDonkeys";
export const SOLANART_LINK = "https://solanart.io/collections/flunkdonkeys";
