import React from "react";
import Hero from "./HeroSection/Hero";
import RaritySection from "./ContentSections";

export default class Landing extends React.Component {
  render() {
    return (
      <>
        <Hero />
        <RaritySection />
      </>
    );
  }
}
