import * as web3 from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import NavBar from "../Components/NavBar";
import { Header, CopyContainer, Button } from "../Components/Common";
import {
  Container,
  Overlay,
  Card,
  CurrencySuffix,
  InfoContainer,
} from "./styles";
import { DonkConnector, useDonkConnector } from "donk-connector";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import idl from "./idl";
import { FdStaking } from "./types/fd_staking";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Flex, Link, Spinner, Text } from "@chakra-ui/react";

type FdStakingProgram = anchor.Program<FdStaking>;
type MintConfig = Awaited<
  ReturnType<FdStakingProgram["account"]["mintConfig"]["fetch"]>
>;
type Base = Awaited<ReturnType<FdStakingProgram["account"]["base"]["fetch"]>>;
const PROGRAM_ID = new web3.PublicKey(
  "BRAPmhHpjPoZaDFF8acjG2goT9dCrm1p5NT4B97b4zkA"
);

function StakePage() {
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const { mints, mint: _mint } = useDonkConnector();
  const mint = _mint ? new web3.PublicKey(_mint) : undefined;
  const [accounts, setAccounts] = useState<Record<string, web3.PublicKey>>();
  const [bumps, setBumps] = useState<Record<string, number>>();
  const [escrowBalance, setEscrowBalance] = useState<number>();
  const [program, setProgram] = useState<FdStakingProgram>();
  const [base, setBase] = useState<Base>();
  const [mintConfig, setMintConfig] = useState<MintConfig>();
  const [staking, setStaking] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);

  const stake = async () => {
    if (!program || !base || !mintConfig || !wallet || !accounts || !mint)
      return;

    try {
      setStaking(true);
      await program.rpc.stake({
        accounts: {
          token: accounts.token,
          mint,
          config: accounts.mintConfig,
          base: accounts.base,
          escrow: accounts.escrow,
          treasury: base.treasury,
          brap: base.brap,
          user: wallet.publicKey,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: web3.SYSVAR_CLOCK_PUBKEY,
          rent: web3.SYSVAR_RENT_PUBKEY,
        },
      });
      setMintConfig(
        await program.account.mintConfig.fetch(accounts.mintConfig)
      );
      toast.success("Stake success!");
    } catch (e) {
      console.error(e);
      toast.error("Stake error!");
    } finally {
      setStaking(false);
    }
  };

  const unstake = async () => {
    if (!program || !base || !mintConfig || !wallet || !accounts || !mint)
      return;

    try {
      setStaking(true);
      await program.rpc.unstake({
        accounts: {
          token: accounts.token,
          mint,
          config: accounts.mintConfig,
          base: accounts.base,
          user: wallet.publicKey,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      });

      setMintConfig(
        await program.account.mintConfig.fetch(accounts.mintConfig)
      );
      toast.success("Unstake success!");
    } catch (e) {
      console.error(e);
      toast.error("Unstake error!");
    } finally {
      setStaking(false);
    }
  };

  const withdraw = async () => {
    if (
      !program ||
      !base ||
      !mintConfig ||
      !wallet ||
      !accounts ||
      !bumps?.escrow
    )
      return;

    const userBrapAta = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      base.brap,
      wallet.publicKey
    );

    try {
      setWithdrawing(true);
      await program.rpc.withdrawEscrow(bumps.escrow, {
        accounts: {
          userBrapAta,
          escrow: accounts.escrow,
          base: accounts.base,
          treasury: base.treasury,
          brap: base.brap,
          user: wallet.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: web3.SYSVAR_RENT_PUBKEY,
        },
      });
      const balance = await connection.getTokenAccountBalance(accounts.escrow);
      setEscrowBalance(balance.value.uiAmount || 0);
      toast.success("Withdraw success!");
    } catch (e) {
      console.error(e);
      toast.error("Withdraw error!");
    } finally {
      setWithdrawing(false);
    }
  };

  useEffect(
    () => {
      if (!wallet?.publicKey || !mint) return;

      anchor.setProvider(new anchor.AnchorProvider(connection, wallet, {}));
      const program = new anchor.Program(idl, PROGRAM_ID);

      const fetch = async () => {
        const [_escrow, escrowBump] = await web3.PublicKey.findProgramAddress(
          [Buffer.from("escrow"), wallet.publicKey.toBytes()],
          PROGRAM_ID
        );

        const [_mintConfig] = await web3.PublicKey.findProgramAddress(
          [Buffer.from("config"), mint.toBytes()],
          PROGRAM_ID
        );

        const [_base] = await web3.PublicKey.findProgramAddress(
          [Buffer.from("base")],
          PROGRAM_ID
        );

        const mintConfig = await program.account.mintConfig.fetch(_mintConfig);
        const base = await program.account.base.fetch(_base);
        let balance;
        try {
          balance = await connection.getTokenAccountBalance(_escrow);
        } catch (e) {}

        const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
          wallet.publicKey,
          { mint }
        );

        // Find token account holder of mint
        const token = tokenAccounts.value.find(
          (a) => a.account.data.parsed?.info?.tokenAmount?.uiAmount > 0
        )?.pubkey as web3.PublicKey;

        setAccounts({
          escrow: _escrow,
          mintConfig: _mintConfig,
          base: _base,
          token,
        });

        setBumps({
          escrow: escrowBump,
        });

        setEscrowBalance(balance?.value?.uiAmount || 0);
        setMintConfig(mintConfig);
        setBase(base);
        setProgram(program);
      };

      fetch();
    },
    // eslint-disable-next-line
    [wallet?.publicKey, _mint]
  );

  return (
    <>
      <NavBar white mintpage />
      <Container>
        <ToastContainer theme="dark" />
        <Overlay>
          <Card>
            <CopyContainer>
              <Header>Stake a donk</Header>
              <Flex justifyContent="center" mb="20px">
                <DonkConnector />
              </Flex>
              {wallet?.publicKey ? (
                <>
                  {mints.length > 0 ? (
                    <>
                      <InfoContainer>
                        <table>
                          <tbody>
                            <tr>
                              <th>Donk</th>
                              <td>
                                {mintConfig ? (
                                  <>Flunk Donkey #{mintConfig?.metadata.id}</>
                                ) : (
                                  <>&mdash;</>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Rank</th>
                              <td>
                                {mintConfig ? (
                                  <>{mintConfig?.metadata.rank} / 5678</>
                                ) : (
                                  <>&mdash;</>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Staked</th>
                              <td>
                                {mintConfig?.stakeStartTs ? (
                                  new Date(
                                    (
                                      mintConfig?.stakeStartTs as anchor.BN
                                    ).toNumber() * 1000
                                  ).toLocaleDateString("en-US")
                                ) : (
                                  <>&mdash;</>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Balance</th>
                              <td>
                                {escrowBalance?.toFixed(6)}{" "}
                                <CurrencySuffix>$BRAP</CurrencySuffix>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </InfoContainer>
                      {mintConfig?.staker ? (
                        <Button
                          disabled={!mint || staking}
                          style={{ marginBottom: 10 }}
                          onClick={() => unstake()}
                        >
                          Unstake
                          {staking && <Spinner ml={4} />}
                        </Button>
                      ) : (
                        <Button
                          disabled={!mint || staking}
                          style={{ marginBottom: 10 }}
                          onClick={() => stake()}
                        >
                          Stake
                          {staking && <Spinner ml={4} />}
                        </Button>
                      )}
                      <Button
                        disabled={!escrowBalance || withdrawing}
                        onClick={() => withdraw()}
                      >
                        Withdraw $BRAP
                        {withdrawing && <Spinner ml={4} />}
                      </Button>
                      {accounts?.escrow && (
                        <Text fontSize="xs" mt={2}>
                          $BRAP Escrow
                          <br />
                          <Link
                            href={`https://solscan.io/account/${accounts.escrow.toBase58()}`}
                            isExternal
                          >
                            {accounts.escrow.toBase58()}
                          </Link>
                        </Text>
                      )}
                    </>
                  ) : (
                    <p>No donks in wallet.</p>
                  )}
                </>
              ) : (
                <p>No wallet connected.</p>
              )}
            </CopyContainer>
          </Card>
        </Overlay>
      </Container>
    </>
  );
}

export default StakePage;
