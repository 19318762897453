import React from "react";
import PfpFlipper from "../../Components/PfpFlipper";
// import MintNow from "../Components/MintNow";
import StevePicture from "../../images/donkeys/steve.jpg";
import {
  Header,
  Text,
  SectionContainer,
  CopyContainer,
  CopyCard,
} from "../../Components/Common";
import Roadmap from "./Roadmap";
import TeamSection from "./TeamSection";
import Lore from "./Lore";
import {
  Content,
  FAQ,
  Card,
  HallwayContainer,
  MarketplaceLink,
} from "./styles";

class RaritySection extends React.Component {
  render() {
    return (
      <>
        <SectionContainer centeredLargeScreens>
          <Content>
            <PfpFlipper />
            <CopyContainer>
              <Header>Welcome to College</Header>
              <Text>
                Campus life here is vibrant - 5,678 donks engage in a large
                variety of activities such as shoplifting, competition drinking,
                and toilet papering the nearby yacht club.
              </Text>
              <Text>
                We are your easy and safe entry into one of the oldest Solana
                NFT brands and communities, led by experienced investors and
                degens celebrating failures and success (mostly the former),
                comfortable with not living up to our full potential.
              </Text>
              <Text>
                An expanding NFT and token ecosystem providing a truly
                customized and fun experience at the genesis of the Web 3
                revolution.
              </Text>
            </CopyContainer>
          </Content>
        </SectionContainer>
        <SectionContainer id="marketplaces">
          <HallwayContainer>
            <CopyCard slim>
              <Header>Marketplaces</Header>
              <MarketplaceLink
                href="https://solanart.io/collections/flunkdonkeys"
                target="_blank"
              >
                Solanart
              </MarketplaceLink>
              <MarketplaceLink
                href="https://magiceden.io/marketplace/flunk_donkeys"
                target="_blank"
              >
                Magic Eden
              </MarketplaceLink>
              <MarketplaceLink
                href="https://opensea.io/collection/fdcc"
                target="_blank"
              >
                OpenSea
              </MarketplaceLink>
              <MarketplaceLink
                href="https://alpha.art/collection/flunk-donkeys"
                target="_blank"
              >
                Alpha Art
              </MarketplaceLink>
              <MarketplaceLink
                href="https://digitaleyes.market/collections/Flunk%20Donkeys"
                target="_blank"
              >
                Digital Eyes
              </MarketplaceLink>
              <MarketplaceLink
                href="https://ftx.us/nfts/collection/Flunk%20Donkeys"
                target="_blank"
              >
                FTX
              </MarketplaceLink>
            </CopyCard>
          </HallwayContainer>
        </SectionContainer>
        <Roadmap />
        <TeamSection />
        <SectionContainer centeredLargeScreens>
          <Content>
            <CopyContainer id="questions">
              <Header>This is Steve</Header>
              <Text>
                Do you look like Steve right now? Are you confused? Are you
                grappling with existential questions that drive you to
                compulsively purchase NFTs in a futile attempt to fill some void
                inside your soul?
              </Text>
              <Text>
                Well don't worry, we have answers! Just reach out to any of our
                faculty on{" "}
                <a href="https://discord.com/invite/Bvg4hp6y8D">Discord</a> or
                check out the FAQ below.👇
              </Text>
            </CopyContainer>
            <div id="steve">
              <img src={StevePicture} alt="Steve the stupid donkey" />
            </div>
          </Content>
        </SectionContainer>
        <Lore />
        <SectionContainer id="faq">
          <FAQ>
            <Card>
              <CopyContainer slim>
                <Header>Frequently Asked Questions</Header>
                <Text>
                  <b>What are the perks of the Genesis/OG collection?</b>
                  <br />
                  <ul>
                    <li>
                      Earn a $BRAP utility token by staking your NFT (See{" "}
                      <a
                        href="https://docs.google.com/document/d/1Ny4OrB-aToVTLWPCBme_T0HkU-NwsRHa8cXczTBK8PY/edit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Whitepaper
                      </a>{" "}
                      for tokenomics)
                    </li>
                    <li>
                      $BRAP is the only way to purchase Gen 2 Attribute packs to
                      fuse your custom Gen 2 NFT.
                    </li>
                    <li>
                      Member of the Decentralized Autonomous Organization (DAO)
                      and its Vault
                    </li>
                  </ul>
                </Text>
                <Text>
                  <b>Is it true there will be a few rare 1/1 Donkeys?</b>
                  <br />
                  If the myths are to be believed, on mint day some may find
                  themselves with an ultra rare "Campus Legend" donkey. It could
                  all be a myth though.
                </Text>
                <Text>
                  <b>What perks do you get for the Gen 2 Collection?</b>
                  <br />
                  <ul>
                    <li>
                      Build a 100% custom one-of-a-kind brandable character
                    </li>
                    <li>Member of the Delinquency Committee and it's Fund</li>
                  </ul>
                </Text>
                <Text>
                  <b>What perks do you get for both Collections?</b>
                  <br />
                  <ul>
                    <li>Access to Alpha channel (All)</li>
                    <li>Full IP and brand ownership of your NFT characters</li>
                    <li>Eligible for additional prizes, rewards and mints</li>
                  </ul>
                </Text>

                <Text>
                  <b>
                    Will I own the intellectual rights to my donkey after
                    purchase?
                  </b>
                  <br />
                  Wow, what do you take us for, a bunch of jpeg pimps? Obviously
                  once minted you retain full ownership rights over your donkey.
                  Go ahead and marry it for all we care (but don't forget to
                  invite us to the wedding!)
                </Text>
              </CopyContainer>
            </Card>
          </FAQ>
        </SectionContainer>
      </>
    );
  }
}

export default RaritySection;

//         <div className="Graduate-Font h2 bold">Rarity Breakdown</div><br/>
//         <div className="h2">8 Campus Legend Donkeys</div><br/>
//         <div className="h2">12 Super Popular Donkey Traits</div><br/>
//         <div className="h2">20 Really Cool Donkey Traits</div><br/>
//         <div className="h2">35 Nice Donkeys Traits</div><br/>
//         <div className="h2">35 Unpopular Donkeys Traits</div><br/>
//         <div className="h2">10 No Life Donkeys Traits</div>

//         <br/><br/><br/>
//         <div className="h2">Total Traits: 150</div><br/>
//         <div className="h2">Total Donkeys: 10,000</div>
//         <br/><br/>
