import styled from "@emotion/styled";
import { BREAKPOINTS, COLORS, boxShadow } from "../../Components/Common";
import hallwayPicture from "../../images/hallway.jpg";

export const Content = styled.div`
  display: flex;
  max-width: 1600px;
  align-items: center;
  > div {
    flex: 1;
  }

  @media ${BREAKPOINTS.tabletDown} {
    flex-direction: column;
    #questions {
      order: 2;
    }

    #steve {
      order: 1;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const ColoredContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${COLORS.black};
  padding: 25px;
  color: white;
`;

export const HallwayContainer = styled(ColoredContent)`
  background: url("${hallwayPicture}") 0% 0% / cover no-repeat;
`;

export const FAQ = styled(ColoredContent)`
  color: black;
  background: ${COLORS.blue};

  p {
    text-align: left;
  }

  ul {
    list-style-position: inside;
    margin-left: 10px;
  }

  a {
    color: ${COLORS.blue};
  }
`;

export const RoadmapContent = styled(ColoredContent)`
  background: ${COLORS.blue};
  h2 {
    color: white;
  }
  h3 {
    font-weight: bold;
    font-size: 1.2em;
  }
  color: black;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: ${boxShadow};
`;

export const MarketplaceLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export const List = styled.ul`
  padding: 0;

  li {
    position: relative;
    background: white;
    border-radius: 10px;
    text-align: initial;
    padding: 10px 15px;
    margin-bottom: 35px;
    list-style-type: none;

    box-shadow: ${boxShadow};
    > p {
      font-size: 1.2em;
    }
    h3,
    p {
      margin: 8px 0;
    }

    &:after {
      content: "";
      background: white;
      position: absolute;
      height: 35px;
      width: 5px;
      left: 50%;
      bottom: -35px;
    }

    &:last-of-type:after {
      display: none;
    }
  }
`;
