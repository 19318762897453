import React from "react";
import styled from "@emotion/styled";
import {
  SectionContainer,
  Text,
  Header,
  BREAKPOINTS,
  COLORS,
  boxShadow,
  envelopeBackground,
} from "../../../Components/Common";

import team from "./team";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 10px;

  img {
    height: 135px;
    width: 135px;
    border-radius: 100%;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${boxShadow};
  background: white;
  padding: 5px;
  width: 175px;
  p {
    font-weight: bold;
    font-family: Schoolbell;
    text-align: center;
  }
  p:nth-of-type(2) {
    font-size: 13px;
    font-family: Sora;
    font-weight: 300;
    margin: 0;
    margin-bottom: 10px;
  }
`;

const Profile = ({ title, twitterLink, twitterName, imageUrl, job }) => {
  return (
    <ProfileContainer>
      <img src={imageUrl} alt="profile-img" />
      <Text>
        <b>
          <a href={twitterLink}>{twitterName}</a>
        </b>
      </Text>
      <TitleCard>
        <Text>{title}</Text>
        <Text>{job}</Text>
      </TitleCard>
    </ProfileContainer>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px;
  background-color: ${COLORS.lightGreen};
  background-image: ${envelopeBackground};
  background-attachment: fixed;
  background-size: cover;
  h2 {
    margin-bottom: 50px;
    color: white;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1300px;

  @media ${BREAKPOINTS.mobileDown} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TeamSection = () => {
  return (
    <SectionContainer id="team">
      <Content>
        <Header>Faculty</Header>
        <TeamContainer>
          {team.map((member) => (
            <Profile {...member} />
          ))}
        </TeamContainer>
      </Content>
    </SectionContainer>
  );
};

export default TeamSection;
