import React from "react";
import styled from "@emotion/styled";
import Countdown from "react-countdown";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Flex, Button as ChakraButton } from "@chakra-ui/react";
export const COLORS = {
  blue: "#4db9f7",
  green: "#c1f4ef",
  lightGreen: "#a7ffa7",
  darkGreen: "#239c44",
  hoverGreen: "#1c8839",
  fantasyGreen: "#00d67d",
  black: "#181b1d",
  transparentBlack: "rgb(24 27 29 / 78%)",
  transparentWhite: "rgb(255 255 255 / 76%)",
  navGradientStart: "#71a6a7",
  navGradientEnd: "#8bced4",
  fantasyBlack: "#000314",
  fantasyPink: "#e6158c",
  disabled: "#cccccc",
};

export const BREAKPOINTS = {
  largeSceenUp: "(min-width: 1600px)",
  laptopScreenDown: "(max-width: 1200px)",
  tabletDown: "(max-width: 800px)",
  smallScreenDown: "(max-width: 700px)",
  mobileDown: "(max-width: 500px)",
};

export const PageContainer = styled(Flex)`
  height: calc(100vh - 80px);
`;

export const FantasyLeagueBackground = styled(PageContainer)`
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='1200' preserveAspectRatio='none' viewBox='0 0 1440 1200'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1702%26quot%3b)' fill='none'%3e%3crect width='1440' height='1200' x='0' y='0' fill='url(%23SvgjsLinearGradient1703)'%3e%3c/rect%3e%3cpath d='M1440 0L1134.07 0L1440 70.06z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1134.07 0L1440 70.06L1440 314.96000000000004L991.27 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M991.27 0L1440 314.96000000000004L1440 327.53000000000003L581.99 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M581.99 0L1440 327.53000000000003L1440 941.0799999999999L325.89 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 1200L631.13 1200L0 1036.58z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 1036.58L631.13 1200L649.24 1200L0 893.8699999999999z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 893.87L649.24 1200L1092.75 1200L0 818.56z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 818.56L1092.75 1200L1179.93 1200L0 691.41z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1702'%3e%3crect width='1440' height='1200' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='4.17%25' y1='-5%25' x2='95.83%25' y2='105%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1703'%3e%3cstop stop-color='rgba(0%2c 3%2c 20%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(91%2c 0%2c 126%2c 1)' offset='0.99'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e")
    0% 0% / cover no-repeat;
`;

export const boxShadow = `10px 10px 0 ${COLORS.blue}`;

export const Header = styled.h2`
  font-family: Schoolbell;
  font-size: 2em;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 20px;
  @media ${BREAKPOINTS.mobileDown} {
    font-size: 1.3em;
  }
`;

export const Text = styled.p`
  font-family: Sora;
  font-size: 1.2em;
  line-height: 1.5;
  @media ${BREAKPOINTS.mobileDown} {
    font-size: 1em;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;

  ${(p) =>
    p.centeredLargeScreens &&
    `
  @media ${BREAKPOINTS.largeSceenUp} {
    padding: 50px;
    justiy-content: center;
  }
  `}
`;

export const CopyContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 25px;
  margin-bottom: 30px;
  > p {
    margin-bottom: 20px;
  }
  max-width: ${(p) => (p.slim ? "800px" : "1600px")};
`;

export const CopyCard = styled(CopyContainer)`
  background: ${COLORS.transparentBlack};
`;

export const Button = styled.button`
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: ${COLORS.darkGreen};
  color: white;
  padding: 14px;
  font-family: Schoolbell;

  &:hover {
    background: ${COLORS.hoverGreen};
  }

  &:disabled {
    background: ${COLORS.disabled};
    cursor: default;
  }
`;

const StyledTimer = styled.span`
  font-family: Schoolbell;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 3px;

  > span {
    font-size: 15px;
    color: grey;
  }
`;

export const Timer = ({ date, children }) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return children;
    } else {
      return (
        <StyledTimer>
          {hours}
          <span>h</span>:{minutes}
          <span>m</span>:{seconds}
          <span>s</span>
        </StyledTimer>
      );
    }
  };
  return <Countdown date={date} renderer={renderer} />;
};

export const WalletConnector = styled(WalletMultiButton)`
  background: white;
  border-radius: 0;
  border: 1px solid black;
  padding: 5px 15px;
  color: black;
  font-size: 20px;
  margin-right: 20px;
  font-weight: 500;
  font-family: Schoolbell;
`;

export const MobileWalletConnector = styled(WalletMultiButton)`
  background: transparent;
  border-radius: 0;
  color: black;
  font-size: 20px;
  font-weight: 500;
  font-family: Schoolbell;
  height: unset;
  line-height: unset;
  padding: 10px 15px;

  &&&:hover {
    background: transparent;
  }
`;

export const envelopeBackground = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");`;

export const DarkThemeCard = styled(Flex)`
  border: 1px solid white;
  border-radius: 14px;
  background: ${COLORS.fantasyBlack};
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

export const DarkButtonPrimary = styled(ChakraButton)`
  font-family: Sora;
  background: ${COLORS.fantasyGreen};
  border-none;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  font-size: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 240px;
  height: 50px;

  &:hover {
    background: white;
    color: black;
  }
`;
